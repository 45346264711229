import React from 'react';
import LayoutProvider from "../../../components/Layout"
import CertificadosTramitesComponent from "../../../components/CertificadosComponents/CertificadosTramites"

const CertificadosReclamo = ({location}) => {
    return ( 

        <LayoutProvider title="Certificados de Reclamo" location={location}>

        <CertificadosTramitesComponent
        location={location}
        />

        </LayoutProvider>



     );
}
 
export default CertificadosReclamo;