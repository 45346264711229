import React from "react"
import { useSelector } from "react-redux"
import TablaSeguimientoComponent from "../ReclamosComponents/Tabla/TablaSeguimiento"
import NoInfoComponent from '../../Utils/NoInfo'; 

const CertificadosTramitesComponent = ({
  location,
}) => {
  const ReclamosTramites = useSelector(
    state => state.ReclamosTramites.ReclamosTramites
  )
  const Status = useSelector(state => state.ReclamosTramites.status)

  return (
    <>
      
        <div className="contenedor">
          {Status === 200 ? (
              <TablaSeguimientoComponent
                ReclamosTramites={ReclamosTramites}
                location={location}
              />
          ) : (
           
           <NoInfoComponent/>
          )}
        </div>
    </>
  )
}

export default CertificadosTramitesComponent
