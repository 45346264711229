import React from 'react';
import { navigate } from "gatsby"
import { FaArrowCircleLeft } from "react-icons/fa"
import { useSelector } from "react-redux"

const NoInfoComponent = () => {
    const Buscando = useSelector(
      state => state.ReclamosTramites.buscando
    )

    return ( 
        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-certificados" >
            { Buscando ?
              <div> 
                <div className="spinner-border spinner-border-sm ml-2" role="status" />  
                <b>&emsp;Buscando Trámites </b> 
              </div>
              :
              <div className="d-flex" onClick={() => navigate("/inicio/")}>
                <div>
                  <FaArrowCircleLeft
                    style={{
                      fontSize: 24,
                      fontWeight: "normal",
                      color: "#0073ba",
                      marginTop: 15,
                    }}
                  />
                </div>
  
                <div className="ml-3">
                  <p
                    className="py-0"
                    style={{
                      color: "#444444",
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    Sin información
                  </p>
                  <small style={{ display: "block" }}>
                    Usted no posee información asociada a este módulo. Click para volver al menu principal.
                  </small>
                </div>
              </div>
            }
          </div>
        </div>

     );
}
 
export default NoInfoComponent;